import EcLogo from '../../../static/partners/ec.png';
import RsmcLogo from '../../../static/partners/rsmc.png';
import RsfLogo from '../../../static/partners/rsf.png';
import CobLogo from  '../../../static/partners/cob.png';
import BrgLogo from '../../../static/partners/burlingame.png';
import PffLogo from  '../../../static/partners/pff.png';
import ActLogo from '../../../static/partners/act.png';
import SccLogo from '../../../static/partners/scc.png';
import HaasLogo from '../../../static/partners/haas-stacked.png';
import LaurelLogo from '../../../static/partners/laurel.png';
import FopcLogo from '../../../static/partners/fopc.png';
import CopLogo from '../../../static/partners/cop.png';
import PepsiLogo from '../../../static/partners/pepsico.png';
import GreenhouseLogo from '../../../static/partners/greenhouse.png';
import BerkeleyLogo from '../../../static/partners/ucb.png';

const partnerImages = [
    {
      name: 'HaasLogo',
      url: 'https://haas.berkeley.edu/',
      img: HaasLogo,
    },
    {
      name: 'PffLogo',
      url: 'https://plastic-free-future.org/',
      img: PffLogo,
    },
    {
      name: 'ActLogo',
      url: 'https://actcatering.com/',
      img: ActLogo,
    },
    {
      name: 'CobLogo',
      url: 'https://berkeleyca.gov/',
      img: CobLogo,
    },
    /*
    {
      name: 'LaurelLogo',
      url: 'https://laureldistrictassociation.org/',
      img: LaurelLogo,
    },
    */
    {
      name: 'RsmcLogo',
      url: 'https://www.reusablesanmateocounty.org/',
      img: RsmcLogo,
    },
    {
      name: 'bur_logo',
      url: 'https://www.burlingame.org/',
      img: BrgLogo,
    },
    {
      name: 'RsfLogo',
      url: 'https://reusablesf.org/',
      img: RsfLogo,
    },
    {
      name: 'EcLogo',
      url: 'https://ecologycenter.org/',
      img: EcLogo,
    },
    {
      name: 'SccLogo',
      url: 'https://sustainablecoco.org/',
      img: SccLogo,
    },
    /*
    {
      name: 'FopcLogo',
      url: 'https://friendsofpinolecreek.org/',
      img: FopcLogo,
    },
    */
    {
      name: 'CopLogo',
      url: 'https://www.ci.pinole.ca.us/',
      img: CopLogo,
    },
    {
      name: 'PepsiLogo',
      url: 'https://www.pepsico.com/',
      img: PepsiLogo,
    },
    {
      name: 'GreenHouseLogo',
      url: 'https://www.greenhouse.com/',
      img: GreenhouseLogo,
    },
    {
      name: 'BerkeleyLogo',
      url: 'https://www.berkeley.edu/',
      img: BerkeleyLogo,
    }
  ]

export default partnerImages;
