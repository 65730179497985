import React, {useState, useEffect} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const randomizeImages = (displayImages) => {
  const images = [...displayImages];
  for (let i = images.length - 1; i >= 0; i--) {
    const swapPosition = Math.floor(Math.random() * (i + 1));
    const temp = images[i];
    images[i] = images[swapPosition];
    images[swapPosition] = temp;
  }

  return images;
};

const IMG_HEIGHT = 150;

const Carousel = ({images,slidesToShow}) => {
  const [randomizedImages, setRandomizedImages] = useState([]);

  const SliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 2,
    // autoplay: true,
    speed: 800,
    autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive:[
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShow || 2,
          centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
          slidesToScroll: 2
        }
      }
    ]
  };

  useEffect(() => {
    setRandomizedImages(randomizeImages(images));
  }, [images]);

  return (
    <Slider className="mx-10 px-2 md:px-8 mt-12" {...SliderSettings}>
      {randomizedImages.map((logo) => (
        <a href={logo.url} target="_blank" rel="noreferrer" key={logo.name}>
          <div className='px-6 md:px-3'>
            <img
              src={logo.img}
              alt={logo.alt}
              quality={95}
              style = {{height: IMG_HEIGHT, objectFit: "contain",}}
            />
          </div>
        </a>
      ))}
    </Slider>
  );
};

export default Carousel;
