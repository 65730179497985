import React from 'react';
import {Link as ScrollLink, Element} from 'react-scroll';
import Button from '../components/ui/Button';
import CircleNumber from '../components/CircleNumber';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HeroImage from '../../static/business/recycle.png';
import Bowl from '../../static/business/Bowl-image.png';
import Dollar from '../../static/business/icon_money.svg';
import Container from '../../static/business/img1.png';
import Data from '../../static/business/icon_data.svg';
import Plant from '../../static/business/icon_plant.svg';
import Measure from '../../static/business/icon_measure.svg';
import Collage from '../../static/business/collage.png';
import PlugPlay from '../../static/business/icon_plugPlay.svg';
import ContactForm from '../components/ContactForm';
import Carousel from '../components/Carousel';
import partnerImages from '../components/carousel-images/PartnerImages';
import Signup from '../components/Signup';
import {css} from 'styled-components';
import {Link} from 'gatsby';

const Business = () => (
  <Layout>
    <section className="py-20">
      <div className="container mx-auto lg:flex">
        <div className="lg:w-1/2 pt-20 lg:pt-0 lg:pr-44 lg:text-left text-center flex flex-col justify-center">
          <h1 className="text-6xl leading-none mb-15 font-header font-medium">
            <span className="block">Smart,</span>
            <span className="block">Circular,</span>
            <span className="block">Sustainable.</span>
          </h1>
          <p className="font-body text-2xl px-2 md:px-16 lg:px-0 mt-6">
            Reduce waste, cut costs, and elevate your dining experience through
            advanced technology.
          </p>
        </div>
        <div className="lg:w-1/2 pt-20 lg:pt-0 flex justify-center">
          <img
            src={HeroImage}
            alt={'Hero'}
            quality={95}
            className="w-auto h-64"
          />
        </div>
      </div>
    </section>
    <section className="flex flex-col md:flex-row h-64 h-auto">
      <div className="flex-1 bg-yellow flex justify-center items-center py-20">
        <img src={Bowl} alt="Bowl" className="mx-auto max-w-full max-h-full" />
      </div>
      <div className="flex-1 flex justify-center items-center bg-white">
        <div className="p-8 text-left">
          <h1 className="text-5xl lg:text-6xl leading-none mb-15 font-header">Ease of use</h1>
          <p className="font-body mt-5 font-light">
            <span className="text-xl lg:text-3xl block font-bold">
              Conveniently incorporate a reuse program without interrupting your
              operations.
            </span>
            <span className="text-lg lg:text-xl block mt-5">
              We make sure reusables get back to you, so you don’t have to. With
              several technology solutions for every industry and a flexible reuse
              model, we make reuse convenient and seamless for every audience.
            </span>
          </p>
        </div>
      </div>
    </section>
    <section className="py-20 bg-green">
      <div className="container mx-auto px-8 text-center">
        <img src={Dollar} alt="Dollar" className="mx-auto mb-8 w-auto h-35" />
        <h1 className="text-5xl lg:text-6xl font-header">Save up to 50%</h1>
        <h3 className="text-2xl lg:text-4xl font-body font-bold">
          on food packaging costs
        </h3>
        <p className="text-lg lg:text-xl mt-6 font-body font-light">
          compared to single-use packaging
        </p>
      </div>
    </section>
    <section className="bg-yellow flex flex-wrap md:flex-nowrap items-center">
      <div className="w-full md:w-1/2 p-12 text-left">
        <h2 className="text-5xl lg:text-6xl font-header font-bold mb-4">
          A truly circular system
        </h2>
        <p className="font-body mt-5 font-light">
          <span className="text-2xl lg:text-3xl block font-bold">
            Tired of losing your inventory? We got you.
          </span>
          <span className="text-xl block mt-5">
            Achieve
            <span className="font-bold"> 99% return rates </span>
            with an accountability system that is fully customizable.
            Because every reusable counts.
          </span>
        </p>
        <p className="text-2xl font-body">

        </p>
      </div>
      <div className="w-full md:w-1/2">
        <img src={Container} alt="Section Image" className="w-full h-auto" />
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-20">
      <div className="container mx-auto text-center">
        <h2 className="text-5xl lg:text-6xl font-header mx-8 text-center">
          You are making an impact
        </h2>
        <h2 className="text-2xl lg:text-3xl font-body mx-12 text-center">
          Let us help you tell the story
        </h2>
        <div className="flex flex-col lg:flex-row mt-12">
          <div className="flex-1 transform lg:mx-12  translate-y-8 font-body pb-10 mx-auto flex flex-col items-center">
            <img src={Measure} alt="measure" className="mx-auto mb-2 w-35 h-35" />
            <p className="text-3xl font-header mt-6">Measurable Impact</p>
            <p className="text-xl font-body mt-3 mx-12 lg:mx-0 text-center">
              You are missing out on key ESG data. Incorporate your impact into
              your Scope 3 emissions reporting.
            </p>
          </div>
          <div className="flex-1 transform lg:mx-12 translate-y-8 font-body pb-10 mx-auto flex flex-col items-center">
            <img src={Data} alt="data" className="mx-auto mb-2 w-35 h-35" />
            <p className="text-3xl font-header mt-6">Data-driven Decisions</p>
            <p className="text-xl font-body mt-3 mx-12 lg:mx-0 text-center">
              Obtain valuable insights into key KPIs such as return rate, cycle
              time, and asset rotation level to gain a comprehensive
              understanding of your operations.
            </p>
          </div>
          <div className="flex-1 transform lg:mx-12 translate-y-8 font-body pb-10 mx-auto flex flex-col items-center">
            <img src={Plant} alt="plant" className="mx-auto mb-2 w-35 h-35" />
            <p className="text-3xl font-header mt-6">Limitless Scalability</p>
            <p className="text-xl font-body mt-3 mx-12 lg:mx-0 text-center">
              Built to scale across multiple locations and users.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="features" className="bg-white pt-20 pb-24 md:pb-32">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-header">Trusted by</h2>
        <Carousel images={partnerImages}/>
      </div>
    </section>
    <section className="flex flex-wrap md:flex-nowrap items-center bg-green">
      <div className="w-full md:w-1/2 p-12 text-left">
        <h2 className="text-5xl lg:text-6xl font-header mb-4 leading-none">
          <span className="block">Kick off reuse</span>
            <span className="block">with our packaging</span>
        </h2>
        <p className="text-xl lg:text-2xl font-body mb-6">
          Choose from a variety of cups and containers. We carry multiple shapes,
          sizes and materials made to be reused.
        </p>
        <ScrollLink to="contact-form" smooth={true} duration={500}>
          <Button
            className="font-header text-lg"
            variant="contained"
            color="#fff"
          >
            Request a catalog
          </Button>
        </ScrollLink>
      </div>
      <div className="w-full md:w-1/2 p-10">
        <img src={Collage} alt="collage" className="w-full h-auto" />
      </div>
    </section>
    <section className="py-20 bg-yellow">
      <div className="container mx-auto px-8 text-center">
        <img
          src={PlugPlay}
          alt="Plug Play Section"
          className="mx-auto w-35 h-35"
        />
        <h1 className="text-5xl lg:text-6xl font-header">Plug & Play</h1>
        <p className="text-2xl lg:text-4xl font-body font-bold">
          Already have your own reusables?
        </p>
        <p className="text-xl lg:text-2xl font-body mt-4 mx-8">
          Power your own program through our plug & play tech solution.
        </p>
      </div>
    </section>
    <Element name="contact-form">
      <section className="py-20 bg-white">
        <div className="container mx-auto px-8 text-center">
          <h1 className="text-5xl lg:text-6xl mb-15 font-header">Request a demo</h1>
          <ContactForm />
        </div>
      </section>
    </Element>
  </Layout>
);

export default Business;
